body {
  margin: 0;
  font-family: Quicksand, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: $nom-gray-txt;
  text-align: left;
  a {
    color: $nom-blue-medium;
  }

  .container-fluid {
    &.h-100 {
      height: 100vmin;
    }

    .row {
      .content-gray {
        background: $nom-gray-bg;

        .brand-login {
          margin: 2em 0 0 2em;
        }
      }

      .log-slide {
        padding: 0;

        .container {
          padding: 0;

          .carousel,
          .slide {
            .carousel-inner {
              .item {
                .carousel-caption {
                  h3 {
                    text-transform: uppercase;
                    color: $white;
                    letter-spacing: 1.2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-header {
    .logo-wrap {
      width: auto !important;
      padding-top: 0 !important;

      .brand-login {
        margin: 1em 0;
        width: 75% !important;
      }
    }
  }
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.wrapper {
  background: $nom-gray-bg;
  margin-top: rem($margin-top);

  .sidemenu {
    background: rgb(3, 69, 140);
    background: linear-gradient(180deg, $nom-blue-hard 0%, $nom-blue-medium 100%);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    margin: rem($margin-top) auto auto;
    width: rem(220px);
    transition: all ease $easeTime;
    transform: translateX(-100%);
    z-index: 2000;
    overflow-y: auto;
    padding-left: 0;

    &.active {
      transform: translateX(0);
    }

    ul {
      &.navbar-nav {
        li {
          &.nav-item {
            a {
              cursor: pointer;
              color: $white;
              text-decoration: none;
              font-size: 1.25em;
              &.nav-link {
                i {
                  margin-right: rem(4px);
                  font-size: rem(18px);
                  vertical-align: middle;
                  height: rem(32px);
                  width: rem(32px);
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;

                  &.sub {
                    font-size: 8px;
                    float: right;
                  }
                }

                span {
                  &.text {
                    font-size: rem(13px);
                  }
                }

                &.dropdown-btn {
                  i {
                    &.icon-arrow-down {
                      font-size: rem(14px);
                    }
                  }
                }

                &:hover,
                &.active {
                  background: $white;
                  color: $nom-blue-medium;
                  border-radius: 0;
                }

                &.open {
                  i {
                    transition: transform 0.3s linear;

                    &.sub {
                      transition: transform 0.3s linear;
                      transform: rotate(90deg);
                    }
                  }
                }
              }
            }
            &.burger {
              margin: 20px 10px 5px 10px;
            }

            ul {
              position: relative;
              list-style: none;
              padding-left: 20px;
              transition: all 0.6s linear 0.2s;
              max-height: 0;
              overflow: hidden;

              &.open {
                max-height: 500px;
                transition: all 0.4s linear;
              }

              li {
                font-size: 0.9em;
                padding: 6px;
                color: $nom-gray-txt-2;

                i {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  padding-right: 20px;
                  color: inherit;
                }

                a {
                  text-decoration: none;
                  color: inherit;
                  transition: all 0.3s linear;
                }

                &:hover {
                  color: $nom-gray-bg;
                }
              }
            }
          }
        }
      }
    }

    .dropdown-menu-left {
      position: static;
      top: 100%;
      left: 0;
      display: none;
      float: left;
      padding: 0.5rem 0;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      text-align: left;
      list-style: none;
      background-color: $white;
      background-clip: padding-box;
      border: 0;
      border-radius: 0;

      a {
        color: $nom-blue-medium  !important;
      }
    }
  }

  .page-wrapper {
    width: 100%;
    margin-left: rem(0px);
    transition: all ease $easeTime;
    -webkit-animation: fadein 1s;
    -moz-animation: fadein 1s;
    -ms-animation: fadein 1s;
    -o-animation: fadein 1s;
    animation: fadein 1s;

    .row {
      &.title-wraper {
        margin: 1em 0 0 0;
        padding: 0.5em 0 0 0;
        vertical-align: middle;

        h2 {
          color: $nom-blue-hard;
          font-size: 1.35rem;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }

      .tabla-operaciones {
        max-width: 70%;
        display: block;
        margin: 0 auto;
        .btn-nom-content {
          margin-top: 2em;
        }
    }

      &.content-wrapper {
        background: transparent;
        margin: 1em;

        .panel {
          .card-dash {
            margin: 0.75em;
            background: white;
            padding: 1.5em;
          }
        }
      }

      &.content-wrapper-inner {
        background: initial;
        margin: 0 auto 0.7em auto;
        padding: 15px 1em;

        .pagination {
          margin-top: 1em;
        }

        .inner-nav {
          width: 100%;

          li {
            .nav-link {
              &.nav-primary {
                border-radius: 25px;
                background: $nom-gray-input;
                color: $nom-gray-txt;
                margin-right: 0.75em;
                padding: 0.35rem 1.25rem;
                font-size: 0.85rem;
                cursor: pointer;

                &.active {
                  background: $nom-blue-hard;
                  color: $white;
                }

                &:hover {
                  background: $nom-blue-medium;
                  color: $white;
                }
              }

              &.nav-second {
                border-radius: 0;
                background: $white;
                color: $nom-gray-txt;
                border-bottom: solid 1px $nom-gray-input;

                &.active {
                  background: $white;
                  color: $nom-blue-medium;
                  border-bottom: solid 3px $nom-blue-medium;
                }
              }
            }
          }
        }

        .row {
          width: 100%;
        }

        .nom-colapsables {
          #accordion {
            .card {
              position: relative;
              display: flex;
              flex-direction: column;
              min-width: 0;
              word-wrap: break-word;
              background: $white;
              background-clip: border-box;
              border: 1px solid rgba(255, 255, 255, 0.125);
              border-radius: 0;

              .card-header {
                background: rgb(3, 69, 140);
                background: linear-gradient(90deg, $nom-blue-hard 0%, $nom-blue-medium 100%);
                border-bottom: none;
                padding: 0.25rem;
                margin-bottom: 0;

                .btn {
                  border-radius: 0;
                  padding: 0 1em;
                  margin: 0.35em;

                  &.btn-link {
                    font-weight: initial;
                    color: white;
                    text-decoration: none;
                  }
                }
              }

              .collapse {
                .card-body {
                  flex: 1 1 auto;
                  min-height: 1px;
                  padding: 1rem 0;

                  .row {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }

        .card-view {
          background: $white;
          height: auto;
          width: 100%;
          display: flex;
          text-align: center;
          border-radius: 7px;
          border: none;
          padding: 1em;
          color: $nom-blue-hard;
          margin: 1em auto;
          cursor: pointer;
          transition: 0.5s;
          -webkit-box-shadow: 2px 1px 8px 1px $nom-gray-txt-2;
          box-shadow: 2px 1px 8px 1px $nom-gray-txt-2;

          &:hover {
            color: $white;
            background: $nom-blue-hard;
          }

          i {
            font-size: rem(24px);
            margin: 10px 0;
            display: block;
            color: $white  !important;

            &::before {
              background: rgb(3, 69, 140);
              background: linear-gradient(180deg, $nom-blue-hard 0%, $nom-blue-medium 100%);
              border-radius: 50%;
              padding: 15px;
            }
          }

          h6 {
            text-transform: uppercase;
            font-weight: 400;
            text-align: left;
            margin-left: 10px;
          }
        }
      }

      .breadcrumb {
        background-color: transparent;
        border-radius: 0;
      }
    }
  }
}

.footer-btn {
  border-top: 1px solid $nom-gray-input;
  margin: 1em 0;
  padding: 1em 0;
  width: 100%;
}


.wrapper {
  .sidemenu {
    transform: translateX(0);
  }

  & {
    .sidemenu.active {
      width: rem(80px);
      .icon-menu {
        text-align: center !important;
        display: block;
      }
      ul {
        &.navbar-nav {
          li {
            &.nav-item {
              a {
                &.nav-link {
                  text-align: center;

                  i {
                    margin-right: 0;

                    &.icon-arrow-down {
                      display: none;
                    }
                  }

                  span {
                    &.text {
                      display: none;
                    }
                  }

                  &:hover+ul {
                    max-height: 500px;
                  }

                  &.open+ul {
                    max-height: 500px;
                  }
                }
              }

              ul {
                max-height: 0px;

                &:hover {
                  max-height: 500px;
                }

                li {
                  a {
                    span.text {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .page-wrapper {
      margin-left: rem(220px);
    }
  }
}

/** login **/
.background-dis {
  background-image: url("/../assets/img/nomina-slide-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  .table-struct {
    display: table !important;

    .auth-form-wrap {
      margin-top: .15em;

      .auth-form {
        width: 60%;
        padding: 2em 4em;

        &.form-content {
          background: rgba(255, 255, 255, 1);
          color: black;
          border: 1px solid white;
          height: 85vh;

          img {
            &.logo-login {
              width: 40%;
              margin-bottom: 1em;
            }
          }

          .form-wrap {
            form {
              .form-group {
                margin: 1.25em auto;

                input {
                  &.form-control {
                    height: calc(2em + 0.75rem + 2px);
                  }
                }

                a {
                  color: $nom-blue-light;
                }

                .btn-nom-content {
                  background: $nom-blue-light;
                  color: $white;
                  margin-top: 1.5em !important;
                  padding: 0.7em 1.75em;
                  margin: 0.25em;
                  font-size: 0.95em;
                  letter-spacing: 1.25px;
                  border-radius: 0px;
                  font-weight: 600;

                  &:hover {
                    background: $nom-blue-hard;
                    color: $white;
                  }
                }

                .btn.disabled,
                .btn:disabled {
                  opacity: 1 !important;
                  background: $gray-600;
                  color: $white;

                  &:hover {
                    background: $gray-600;
                    color: $white;
                  }
                }

                .col {

                  &.text-danger,
                  &.text-success {
                    padding: 0;
                  }

                  i {
                    &.material-icons {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/** Inputs **/
.form-control {
  border: 1px solid $nom-gray-input;
  border-radius: 0;
  color: $nom-gray-txt-2;
  font-size: inherit;
}

.form-group {
  margin: .25em auto;

  .form-check {
    font-size: 0.9em;
    margin-left: 1em;
  }
}

/**********File Inputs**********/

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  +label {
    font-size: inherit;
    font-weight: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0;
  }
}

.iborrainputfile {
  font-size: inherit;
  font-weight: inherit;
}

.input-upload {
  +label {
    border: 1px solid $nom-gray-input;
    border-radius: 0;
    color: $nom-gray-txt-2;
    font-size: 0.9em;
    width: 100%;
  }

  &:focus+label,
  &.has-focus+label {
    border-color: $nom-gray-input;
  }

  +label {
    &:hover {
      border-color: $nom-gray-input;
    }

    span,
    strong {
      padding: 0.625rem 1.25rem;
    }

    span {
      width: inherit;
      min-height: 1em;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: top;
    }

    strong {
      height: 100%;
      color: $black;
      background-color: $nom-gray-txt-2;
      display: inherit;
      float: right;
    }
  }

  &:focus+label strong,
  &.has-focus+label strong,
  +label:hover strong {
    background-color: $nom-gray-txt;
  }
}

/**********End File Inputs**********/
/** Botones **/


.btn-nom-disabled-2 {
  background: $gray-800  !important;
  color: $white;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
}

.btn-nom-content {
  background: $nom-blue-light;
  color: $white;
  padding: 0.5em 1.75em;
  font-size: 0.95em;
  letter-spacing: 1.25px;
  border-radius: 0px;
  font-weight: 400;
  margin-left: 1em;

  &:hover {
    background: $nom-blue-hard;
    color: $white;
  }
}

.button-fd {
  .btn-nom-content {
    background: $nom-blue-light;
    color: $white;
    padding: 0.5em 1.75em;
    font-size: 0.95em;
    letter-spacing: 1.25px;
    border-radius: 0px;
    font-weight: 400;
    margin-left: 1em;
    width: 100%;
    margin-bottom: 5px;
  }
}

.btn-nom-success {
  background: $green;
  color: $white;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
}

.btn-nom-danger {
  background: $red;
  color: $white;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
}

.btn-nom-warning {
  background: $yellow;
  color: $white;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
}

.btn-nom-disabled {
  background: $nom-gray-txt-2;
  color: $black;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;
}

.modal-footer {
  margin-top: 1.5em;
}

.btn-open {
  color: $nom-gray-txt  !important;
  background: transparent;
  border-radius: 50px;
  font-size: 0.9rem;
  letter-spacing: 1.25px;

  &:hover {
    background: transparent;
    color: $nom-gray-input  !important;
  }

  i {
    font-size: 24px !important;
  }
}

/** Basicos **/
.nw {
  white-space: nowrap;
}

.no-sorting {
  &:after {
    display: none !important;
  }
}

span {
  &.circle-status {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: block;

    &.ac {
      background: $green;
    }

    &.in {
      background: $yellow;
    }

    &.dh {
      background: $red;
    }
  }
}

.h-100 {
  height: 100vmin !important;
}

hr {
  margin-top: 0;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.pull-right {
  float: right;
}

table {
  table-layout: fixed;
}

th,
td {
  padding: 20px;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 2em;
  padding-left: 1.5rem;
  // color-adjust: exact;
  margin-left: 2.2em;
}

input,
select {
  border-radius: 0 !important;
}

.input-group {
  &.select-estatus {
    display: inline-flex;
    width: 50%;
    margin-right: 2em;
  }
}

.input-group-text {
  background: $nom-blue-medium  !important;
  border: $nom-blue-medium 1px solid;
  cursor: pointer;
  font-size: .9rem;
  min-width: 40px;

  &:hover {
    background: $nom-gray-txt  !important;
    border: $nom-gray-txt 1px solid;
  }

  a {
    color: $white  !important;
  }
}

.dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
}

.text-custom {
  color: $nom-blue-medium;
}

.material-icons {
  display: inline-flex;
  vertical-align: middle;
}

.content-gray {
  background: #f0f2f7;
}

.log-slide {
  padding: 0;
}

.table-struct {
  display: table !important;
}

.table-struct .auth-form-wrap {
  padding: 2em 0;
}

.table-struct .auth-form-wrap .auth-form {
  width: 80%;
  padding: 3em 15%;
}

.nav-header .logo-wrap .brand-login {
  margin: 1em 0;
  width: 75% !important;
}

.pull-right {
  float: right;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: red;
}

.custom-switch {
  margin-top: 2.25em;
}

.custom-control {
  margin-left: 0;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: red !important;
  padding-right: calc(1.5em + 1.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.btn-nom-success {
  background: #22af46;
  color: #fff;
  border-radius: 50px;
}

.btn-nom-warning {
  background: #ffbf36;
  color: #fff;
  border-radius: 50px;
}

.modal-backdrop.show {
  opacity: 0.5;
  z-index: 3000 !important;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 3001;
}

.modal-dialog {
  transform: none;
  max-width: 800px !important;
}

.margen {
  margin: 2rem !important;
}

.input-group-addon {
  padding-top: 5px;
  padding-left: 10px;

  .fa {
    &.fa-eye-slash {
      color: $gray-800;
    }
  }
}

//* Estados
.state-active {
  color: $nom-blue-light;
}

.state-inactive {
  color: $nom-gray-txt;
}

.state-enabled {
  color: $green;
}

.state-disabled {
  color: $red;
}

.state-approval {
  color: $yellow;
}

.state-information {
  color: $nom-gray-txt-2;
}

.state-pending {
  color: $nom-orange-1;
}

.ngb-dp-weekday {
  color: $nom-blue-medium  !important;
}

.ngb-dp-navigation-chevron {
  color: $nom-blue-medium  !important;
}

.overlay {
  position: fixed !important;
  display: none;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: small;
}

.p-autocomplete {
  margin: -1em;
  height: inherit;
}

.p-autocomplete-dd {
  .p-autocomplete-dropdown {
    border-radius: 0 !important;
  }

  .p-autocomplete-input {
    flex: 1 1 auto;
    width: 1%;
    border: none !important;
    background: none !important;
  }

  .p-inputtext {
    font-size: 14px;
  }
}

.liquidadas{
  color: rgba(18,146,9) !important;
}

.rechazadas{
  color: rgba(244, 67, 54) !important;
}

.procesando{
  color: rgba(255, 152, 0) !important;
}


.table-responsive .p-datatable .p-datatable-wrapper table thead.p-datatable-thead {
  th:nth-child(1) {
    width: 4%;
  }
  th:nth-child(2) {
    width: 5%;
    //display: none;
  }
  th:nth-child(3) {
    width: 15%;
  }
  th:nth-child(4) {
    width: 15%;
  }
  th:nth-child(5) {
    width: 10%;
  }
  th:nth-child(11) {
    width: 4%;
  }
  th:nth-child(12) {
    width: 4%;
  }
} 
 

/** querys **/

// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .wrapper .sidemenu.active {
    width: 3.5rem;
}

.wrapper{
  .page-wrapper{
    .row{
      &.title-wraper{
        .title-page-fd{
          padding: 0px;
          h2{
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      &.container-lavels-fd{
        padding: 15px 0em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:flex-start;

        .lavel-fd{
          margin-bottom: 15px;
          padding: 0px;
        }

        .button-fd{
          padding: 0px;
        }
      }
      &.container-table-fd{
        padding: 15px 0em;
      }
      &.content-wrapper-inner{
        padding: 15px 0em;
      }
    }
  }
}

.btn-nom-content{
  margin: 0px;
}

}