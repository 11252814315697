table {
    table-layout: fixed;
    background: $white;
    min-height: 200px;
    width: 100%;

    @media (max-width: 575.98px) {
        display: block;
    }

    thead {
        th {
            vertical-align: middle !important;
            line-height: 1em;
        }
    }

    &.empresas {
        thead th:nth-child(1) {
            width: 5%;
        }

        thead th:nth-child(2) {
            width: 10%;
        }

        thead th:nth-child(3) {
            width: 45%;
        }

        thead th:nth-child(4) {
            width: 15%;
        }

        thead th:nth-child(5) {
            width: 13%;
        }

        thead th:nth-child(6) {
            width: 12%;
        }
    }

    .actions-table {
        font-size: 1.25em;

        a {
            &:hover {
                text-decoration: none;
            }

            i {
                padding: 0 0.25em;
            }
        }
    }
}

.table-responsive {
    .p-datatable {
        position: relative;

        &.p-component {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
        }

        .p-datatable-wrapper {
            table {
                border-collapse: collapse;
                table-layout: fixed;
                width: 100%;
                background: $white;

                @media (max-width: 575.98px) {
                    display: block;
                }

                thead {
                    &.p-datatable-thead {
                        tr {
                            &.head-top {
                                th {
                                    height: 45px;
                                    color: $white;
                                    background: $nom-blue-medium;
                                    font-weight: 800;

                                    .p-sortable-column-icon {
                                        color: $white;
                                    }
                                }
                            }

                            &.head-bottom {
                                th {
                                    height: 45px;

                                    .p-column-filter-row .p-column-filter-menu-button {
                                        .pi {
                                            font-size: .8em;
                                            margin-left: 0;
                                        }
                                    }

                                    .p-inputtext {
                                        font-size: .9rem;
                                        max-height: 30px;
                                    }

                             
                                }
                                
                                th:nth-child(1) {
                                    width: 5%;
                                }
                            }

                            th {
                                font-weight: 400;
                                font-size: .9em;

                                i {
                                    &.p-sortable-column-icon {
                                        float: right;
                                    }

                                    &.pi {
                                        font-size: .9em;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }

 
    &.table-responsive {
        thead th:nth-child(1) {
            width: 5%;
        }
    }

    &.operaciones {
        th:nth-child(1) {
            width: 80%;
        }

        th:nth-child(2) {
            width: 20%;
        }
        .head-bottom {
            td {
                i {
                    text-align: center;
                }
            }
        }
    }
}

.tablas-overflow {
    thead {
        th {
            padding: 8px;
        }
    }

    tbody {
        td {
            padding: 3px;

            ul {
                li {
                    list-style: none;
                }
            }
        }
    }
}

//* Estilos del componente con filtros

button.p-column-filter-clear-button {
    display: none;
}

.p-datatable-loading-overlay {
    display: none !important;
}

table {
    &.table-modal {
        thead th:nth-child(1) {
            width: 30%;
        }

        thead th:nth-child(2) {
            width: 15%;
        }

        thead th:nth-child(3) {
            width: 15%;
        }

        thead th:nth-child(4) {
            width: 15%;
        }

        thead th:nth-child(5) {
            width: 25%;
        }
    }
}