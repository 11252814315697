.bg-light {
	background: $white !important;
	border-bottom: 1px solid $nom-gray-txt-2;
	z-index: 2;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 2001;
	&.navbar-expand-lg .navbar-nav {
		flex-direction: column;
		text-align: end;
	}
	.nav-item {
		margin: auto 0.5em;
		color: rgba(0, 0, 0, 0.5);
		i {
			margin-right: rem(4px);
			font-size: rem(20px);
			vertical-align: middle;
			display: inline-flex;
			justify-content: center;
			align-items: center;
		}
		&.dropdown {
			position: relative;
			display: inline-block;
			.dropdown-content {
				display: none;
				position: absolute;
				background-color: $white;
				min-width: 160px;
				overflow: auto;
				box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
				z-index: 1;
				right: 0;
				margin-top: 20px;
				a {
					color: inherit;
					padding: 12px 16px;
					text-decoration: none;
					display: block;
				}
				&.show {
					display: block;
				}
			}
			.dropbtn {
				color: rgba(0, 0, 0, 0.5);
				padding: inherit;
				font-size: inherit;
				background: initial;
				border: none;
				cursor: pointer;
				&::after {
					display: inline-block;
					margin-left: 0;
					vertical-align: 0.255em;
					content: "";
					border-top: 0.3em solid;
					border-right: 0.3em solid transparent;
					border-bottom: 0;
					border-left: 0.3em solid transparent;
				}
				&:hover,
				&:focus {
					background-color: inherit;
				}
			}
		}
	}
}
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    font-size: .9em;
	li {
		a {
			color: $nom-blue-medium;
		}
	}
}
